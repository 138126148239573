import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { openModal } from "../../slices/modals";
import { trashModalIcon } from "../../assets";

const CollapseData = ({
  open,
  setOpen,
  component,
  children,
  collapseTitle,
  collapseDescription,
  categoryTranslations,
  categoryId,
  categoryColor,
  categoryIcon,
  className,
  category,
  tags,
}) => {
  const [openArticles, setOpenArticles] = useState(false);
  const dispatch = useDispatch();
  const { statusDeleteSegmentFromConversation } = useSelector(
    (state) => state.segments
  );
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {component ? (
        component
      ) : (
        <TableRow className="row-collapse" component="div">
          <TableCell
            className={`${
              statusDeleteSegmentFromConversation === "loading" &&
              collapseTitle === t("Segments For Conversation")
                ? "cell-text-collapse delete-segment"
                : "cell-text-collapse"
            } ${category && !categoryId ? "without-category-title" : ""}`}
            component="div"
          >
            <span>
              {collapseTitle === t("Segments For Conversation")
                ? statusDeleteSegmentFromConversation === "loading"
                  ? t("Deleting segment...")
                  : collapseTitle
                : collapseTitle}
            </span>
          </TableCell>
          {category && (
            <>
              <TableCell
                className="cell-btn-collapse"
                align="right"
                component="div"
                onClick={() =>
                  categoryId &&
                  dispatch(
                    openModal("create-category", {
                      name: collapseTitle,
                      description: collapseDescription,
                      color: categoryColor,
                      icon: categoryIcon,
                      translations: categoryTranslations,
                      _id: categoryId,
                    })
                  )
                }
              >
                {categoryId && (
                  <IconButton>
                    <EditIcon style={{ color: "#124E8A" }} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell
                className="cell-btn-collapse"
                align="right"
                component="div"
                onClick={() =>
                  categoryId &&
                  dispatch(
                    openModal("delete-modal", {
                      id: "delete-category",
                      websiteID: user?.websiteID,
                      articleCategoryID: categoryId,
                      articleCategoryTitle: collapseTitle,
                      icon: trashModalIcon,
                      title: t("Are you sure to delete this category ?"),
                      description: (
                        <React.Fragment>
                          {t(
                            "by clicking on delete category, this category will"
                          )}
                          <span>&nbsp;{t("Permanently")}&nbsp;</span>
                          {t("deleted and you will not be able to recover it!")}
                        </React.Fragment>
                      ),
                    })
                  )
                }
              >
                {categoryId && (
                  <IconButton>
                    <DeleteIcon style={{ color: "#EC3862" }} />
                  </IconButton>
                )}
              </TableCell>
            </>
          )}
          <TableCell
            className="cell-btn-collapse"
            align="right"
            component="div"
          >
            <IconButton aria-label="expand row" size="small">
              {category ? (
                openArticles ? (
                  <KeyboardArrowUpIcon onClick={() => setOpenArticles(false)} />
                ) : (
                  <KeyboardArrowDownIcon
                    onClick={() => setOpenArticles(true)}
                  />
                )
              ) : open ? (
                <KeyboardArrowUpIcon onClick={() => setOpen(false)} />
              ) : (
                <KeyboardArrowDownIcon onClick={() => setOpen(true)} />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
      )}

      <TableRow style={{ display: tags && !open && "none" }}>
        <TableCell
          className={
            className ? `${className} collapse-content` : "collapse-content"
          }
          colSpan={6}
        >
          <Collapse
            in={category ? openArticles : open}
            timeout="auto"
            unmountOnExit
          >
            {children}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default CollapseData;
