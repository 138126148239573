import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CustomButton = ({ text, className, image, alt, onClick, style }) => {
  return (
    <div className={className} style={style} onClick={onClick}>
      {image && <LazyLoadImage src={image} alt={alt} />}
      <span>{text}</span>
    </div>
  );
};

export default CustomButton;
